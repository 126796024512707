<template>
  <div class="project-create">
    <CyDetails
      can-cancel
      hide-delete
      :loading="false"
      :on-cancel="() => { $router.push({ name: 'projects' }) }"
      :can-save="formIsValid"
      :on-save="createProject"
      :saving="creating"
      :save-btn-text="$t('createProject')">
      <template #details_formFullWidth>
        <v-container
          fluid
          class="pa-0">
          <v-row
            align="start"
            no-gutters>
            <v-col>
              <h3 v-text="$t('general')"/>
            </v-col>
            <v-col
              cols="8"
              lg="5">
              <CyFormsProject
                @input="projectConfig = $event"
                @is-valid="formIsValid = $event"/>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.lgAndUp"/>
          </v-row>
        </v-container>
      </template>
    </CyDetails>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import CyDetails from '@/components/CyDetails.vue'
import CyFormsProject from '@/components/CyFormsProject.vue'
import { constructBreadcrumb } from '@/utils/helpers'

export default {
  name: 'CyPageProjectCreate',
  components: {
    CyFormsProject,
    CyDetails,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('title'), [
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  header () {
    return {
      title: this.$t('title'),
      description: {
        text: this.$t('gettingStartedText', { url: $docLinks.project.deploy }),
      },
    }
  },
  data: () => ({
    projectConfig: null,
    formIsValid: false,
    creating: false,
  }),
  async created () {
    await this.getStacks()
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('organization/project', [
      'CREATE_PROJECT',
    ]),
    async getStacks () {
      await this.FETCH_AVAILABLE({ keyPath: 'stacks', extraParams: [null, null, null] })
    },
    async createProject () {
      const { formIsValid, projectConfig } = this

      if (!formIsValid) return

      this.creating = true
      // TODO: Remove reference to serviceCatalogRef once the backend is updated
      const noErrorsOccured = await this.CREATE_PROJECT(_.$snakeCaseKeys({ ...projectConfig, serviceCatalogRef: 'seraf:stack-dummy' }))
      if (noErrorsOccured) this.$router.push({ name: 'projectEmpty', params: { projectCanonical: projectConfig.canonical } })
      this.creating = false
    },
  },
  i18n: {
    messages: {
      en: {
        title: 'Create new @:project',
        createProject: 'Create project',
        gettingStartedText: 'Projects allow you to group a set of components, organized into independent environments. To get started, check out our <a class="cy-link" target="_blank" href="{url}">Getting started guide</a> and learn how to make the most of projects.',
      },
      es: {
        title: 'Crear nuevo @:project',
        createProject: 'Crear un proyecto',
        gettingStartedText: 'Los proyectos permiten agrupar un conjunto de componentes, organizados en entornos independientes. Para comenzar, consulte nuestro <a class="cy-link" target="_blank" href="{url}">Guía de inicio</a> y aprenda cómo aprovechar al máximo los proyectos.',
      },
      fr: {
        title: 'Créer un nouveau @:project',
        createProject: 'Créer un projet',
        gettingStartedText: 'Les projets vous permettent de regrouper un ensemble de composants, organisés en environnements indépendants. Pour commencer, consultez notre <a class="cy-link" target="_blank" href="{url}">Guide de démarrage</a> et apprenez comment tirer le meilleur parti des projets.',
      },
    },
  },
}
</script>
